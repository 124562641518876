
import { EventBus } from "@/components/EventBus";
import { ConfirmationDialogRequest, DataClient } from "@/services/DataClient";
import { Utilities } from "@/services/Utilities";
import { Component, Vue } from "vue-property-decorator";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { VBtn, VCard, VDialog } from "vuetify/lib";

interface Question {
    text: string;
    checked: boolean;
}

interface Input {
    type?: "text" | "text-area" | "select";
    label: string;
    value: string;
    items?: Array<{ id: string; name: string }>;
}

interface DebugItem {
    key: string;
    value: string;
}

@Component
export default class extends Vue {
    dialog = false;

    title = "";
    message = "";
    messages: string[] = [];
    warningMessage = "";
    questions: Question[] = [];
    inputs: Input[] = [];
    nocancel = false;
    width = "";
    color = "";
    icon = "";
    iconColor = "";
    buttonColor = "";
    warningMessageChecked = false;
    debugItems: DebugItem[] = [];
    acceptButtonText = "Aceptar";
    cancelButtonText = "Cancelar";
    fullScreen = false;
    bigButtons = false;
    type: "normal" | "warning" | "error" = "normal";
    iconPosition: "top" | "left" = "left";

    mounted() {
        EventBus.$on(DataClient.Dialogs.OpenConfirmationDialogEvent, this.handleOpenEvent);
    }

    beforeDestroy() {
        EventBus.$off(DataClient.Dialogs.OpenConfirmationDialogEvent, this.handleOpenEvent);
    }

    reset() {
        this.dialog = false;
    }

    get textClass() {
        switch (this.type) {
            case "warning":
                return "orange--text";
            case "error":
                return "red--text";
            default:
                return "black--text";
        }
    }

    get dialogDark() {
        switch (this.type) {
            case "warning":
                return true;
            case "error":
                return true;
            default:
                return true;
        }
    }

    get disableAccept() {
        if (this.warningMessage && !this.warningMessageChecked) {
            return true;
        }

        return false;
    }

    async handleOpenEvent(event: ConfirmationDialogRequest) {
        this.title = event.title;
        this.message = event.message || "";
        this.messages = event.messages || [];
        this.warningMessage = event.warningMessage || "";
        this.questions = (event.questions || []).map((q) => ({ text: q, checked: false }));
        this.inputs = (event.inputs || []).map((i) => ({
            type: i.type || "text",
            label: i.label,
            value: i.value || "",
            items: i.items || [],
        }));
        this.nocancel = event.nocancel || false;
        this.color = event.color || "#2B3848";
        this.width = event.width || "600";
        this.icon = event.icon || "";
        this.iconColor = event.iconColor || "orange darken-2";
        this.buttonColor = event.buttonColor || "primary";
        this.warningMessageChecked = false;

        if (event.debug) {
            this.debugItems = Object.keys(event.debug).map((k) => ({ key: k, value: event.debug[k] }));
        } else {
            this.debugItems = [];
        }

        this.fullScreen = event.fullScreen || false;
        this.bigButtons = event.bigButtons || false;

        this.acceptButtonText = event.acceptButtonText || (this.bigButtons ? "SI" : "Aceptar");
        this.cancelButtonText = event.cancelButtonText || (this.bigButtons ? "NO" : "Cancelar");

        if (this.bigButtons) {
            this.width = "100%";
        }

        this.type = event.type || "normal";
        this.iconPosition = event.iconPosition || "left";

        this.dialog = true;

        if (this.inputs.length > 0) {
            Utilities.focus(this, "input-0");
        }
    }

    accept() {
        if (this.inputs.length > 0 && this.questions.length > 0) {
            const inputs = this.inputs.map((i) => i.value || "");
            const questions = this.questions.map((q) => q.checked);
            DataClient.Dialogs.confirmationWithQuestionsAndInputs([true, questions, inputs], true);
        } else if (this.inputs.length > 0) {
            DataClient.Dialogs.confirmationWithInputs([true, ...this.inputs.map((i) => i.value || "")], true);
        } else if (this.questions.length > 0) {
            DataClient.Dialogs.confirmationWithQuestions([true, ...this.questions.map((q) => q.checked)], true);
        } else {
            DataClient.Dialogs.confirmation(true, true);
        }

        this.reset();
    }

    cancel() {
        if (this.inputs.length > 0 && this.questions.length > 0) {
            DataClient.Dialogs.confirmationWithQuestionsAndInputs([false, [], []], true);
        } else if (this.inputs.length > 0) {
            DataClient.Dialogs.confirmationWithInputs([false, ...this.inputs.map((i) => "")], true);
        } else if (this.questions.length > 0) {
            DataClient.Dialogs.confirmationWithQuestions([false, ...this.questions.map((q) => false)], true);
        } else {
            DataClient.Dialogs.confirmation(false, true);
        }

        this.reset();
    }
}
