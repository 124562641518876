import { defineStore } from "pinia";


export const errorStore = defineStore("errorStore", {
    state: () => {
        return {
            error: ""
        };
    },
    actions: {
        showError(error: string) {
            this.error = error;
        },
        hideError() {
            this.error = "";
        }
    }
});
