
import { mapState } from "pinia";
import { Component, Vue, Watch } from "vue-property-decorator";
import { loginStore } from "./stores/LoginStore";
import { errorStore } from "./stores/ErrorStore";
import { EventBus } from "@/components/EventBus";
import { Utilities } from "@/services/Utilities";
import GlobalConfirmationDialog from "@/components/GlobalConfirmationDialog.vue";

import { GetAgentApiTimeRecordResponse, LogAgentApiInResponse } from "@/services/ClientInterfaces";
import { DataClient, ToastRequest } from "@/services/DataClient";

@Component({
    components: {
        GlobalConfirmationDialog,
    },
    computed: {
        ...mapState(loginStore, ["authenticated", "details"]),
        ...mapState(errorStore, ["error"]),
    },
})
export default class extends Vue {
    authenticated!: boolean;
    details!: LogAgentApiInResponse;
    error!: string;
    drawer = false;

    userPanelVisible = false;
    timeRecordForCurrentUser: GetAgentApiTimeRecordResponse | null = null;

    toastVisible = false;
    toast: ToastRequest = {} as ToastRequest;

    timer: any;

    get userName() {
        return this.details?.name || "";
    }

    get agencyName() {
        return this.details?.agencyName || "";
    }

    get agencyImage() {
        return this.details?.agencyImage || "";
    }

    get showHeader() {
        if (this.$route.meta?.hideHeader) {
            return false;
        }

        if (!this.authenticated) {
            return false;
        }

        return true;
    }

    get snackbar() {
        return this.error !== "";
    }

    set snackbar(value: boolean) {
        this.closeError();
    }

    async mounted() {
        loginStore().initialize();

        EventBus.$on("scrollToTop", () => this.scrollToTop());
        EventBus.$on("updateScroll", () => this.updateScroll());
        EventBus.$on(DataClient.Dialogs.OpenToastEvent, (event: ToastRequest) => this.openToast(event));
    }

    destroyed() {
        EventBus.$off(DataClient.Dialogs.OpenToastEvent);

        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }

    logOut() {
        loginStore().logOut();
        this.$router.push({ name: "LogIn" });
    }

    closeError() {
        errorStore().hideError();
    }

    @Watch("$route")
    scrollToTop() {
        const ref = (this.$refs as any).routerScroll as any;
        if (ref && ref.$el) {
            ref.$el.scrollTop = 0;
            this.updateScroll();
        }
    }

    updateScroll() {
        const ref = (this.$refs as any).routerScroll as any;

        setTimeout(() => {
            ref.update();
        }, 100);
    }

    get allowPersonalDocumentation() {
        return loginStore().details?.isEmployee || false;
    }

    get allowNewBookings() {
        return loginStore().details?.allowNewBookings || false;
    }

    get allowElectronicLists() {
        return loginStore().details?.allowElectronicLists || false;
    }

    get allowDocumentation() {
        return loginStore().details?.allowDocumentation || false;
    }

    openToast(event: ToastRequest) {
        this.toast = event;
        this.toastVisible = true;
    }

    clickToast() {
        if (this.toast && this.toast.buttonRoute) {
            this.$router.push(this.toast.buttonRoute);
        }

        this.toastVisible = false;
    }

    @Watch("details", { immediate: false })
    async userDetailsChanged() {
        if (this.details) {
            await this.refreshTimeRecords();

            this.userPanelVisible = this.details.timeRecordEnabled;

            await this.scheduleRefresh();
        } else {
            this.userPanelVisible = false;
        }
    }

    async scheduleRefresh() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }

        this.timer = setTimeout(async () => this.refreshTimeRecords(true), 10000);
    }

    async refreshTimeRecords(schedule = false) {
        try {
            if (this.details?.timeRecordEnabled) {
                this.timeRecordForCurrentUser = await DataClient.Agencies.getTimeRecord();
            } else {
                this.timeRecordForCurrentUser = null;
            }
        } catch (error) {
            console.error(error);
        }

        if (schedule) {
            await this.scheduleRefresh();
        }
    }

    async startTimeRecord() {
        await this.refreshTimeRecords();

        if (!this.timeRecordForCurrentUser) {
            return;
        }

        const date = Utilities.formatDateToDDMMYYYY(this.timeRecordForCurrentUser.date);

        const yes = await DataClient.Dialogs.confirmation({
            title: "Control de jornada laboral " + date,
            message: `<strong>¿Comenzar la jornada laboral del ${date} ahora?</strong>`,
            messages: ["El registro de jornada laboral es obligatorio. Puedes posponerlo, pero no olvides iniciar tu jornada laboral más adelante."],
            acceptButtonText: "Sí, iniciar",
            cancelButtonText: "No",
        });

        if (yes) {
            this.timeRecordForCurrentUser = await DataClient.Agencies.startTimeRecord({});
        }
    }

    async resumeTimeRecord() {
        await this.refreshTimeRecords();

        if (!this.timeRecordForCurrentUser) {
            return;
        }

        const date = Utilities.formatDateToDDMMYYYY(this.timeRecordForCurrentUser.date);

        const yes = await DataClient.Dialogs.confirmation({
            title: "Control de jornada laboral " + date,
            message: `<strong>¿Desea reanudar la jornada laboral del ${date} ahora?</strong>`,
            messages: [
                "A continuación se muestran los periodos contabilizados en su jornada hasta el momento.",
                ...this.timeRecordForCurrentUser.periods.map((period) => `${period.start} - ${period.end} (${period.readableDuration})`),
            ],
            acceptButtonText: "Sí, reanudar",
            cancelButtonText: "No",
        });

        if (yes) {
            this.timeRecordForCurrentUser = await DataClient.Agencies.startTimeRecord({});
        }
    }

    async pauseTimeRecord() {
        await this.refreshTimeRecords();

        if (!this.timeRecordForCurrentUser) {
            return;
        }

        const date = Utilities.formatDateToDDMMYYYY(this.timeRecordForCurrentUser.date);
        const yes = await DataClient.Dialogs.confirmation({
            title: "Control de jornada laboral " + date,
            message: `<strong>¿Desea pausar la jornada laboral del ${date} ahora?</strong>`,
            messages: [
                "A continuación se muestran los periodos contabilizados en su jornada hasta el momento.",
                ...this.timeRecordForCurrentUser.periods.map((period) => `${period.start} - ${period.end || "Hasta ahora"} (${period.readableDuration})`),
            ],
            acceptButtonText: "Sí, pausar",
            cancelButtonText: "No",
        });

        if (yes) {
            this.timeRecordForCurrentUser = await DataClient.Agencies.endTimeRecord({ pause: true });
        }
    }

    async endTimeRecord() {
        await this.refreshTimeRecords();

        if (!this.timeRecordForCurrentUser) {
            return;
        }

        const date = Utilities.formatDateToDDMMYYYY(this.timeRecordForCurrentUser.date);
        const yes = await DataClient.Dialogs.confirmation({
            title: "Control de jornada laboral " + date,
            message: `<strong>¿Desea finalizar la jornada laboral del ${date} ahora?</strong>`,
            messages: [
                "A continuación se muestran los periodos contabilizados en su jornada hasta el momento.",
                ...this.timeRecordForCurrentUser.periods.map((period) => `${period.start} - ${period.end || "Hasta ahora"} (${period.readableDuration})`),
            ],
            acceptButtonText: "Sí, finalizar",
            cancelButtonText: "No",
        });

        if (yes) {
            this.timeRecordForCurrentUser = await DataClient.Agencies.endTimeRecord({});
        }
    }
}
